import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/app/node_modules/.pnpm/next-intl@3.26.5_next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macro_povg5kxv43tl7xftzlgy6tdtgm/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/.pnpm/next@14.2.24_@babel+core@7.26.10_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-dom_eubnhbweha76mlu6jk36figkqm/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/app/public/svg/footer-logo.svg");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/channel-logo/client/ChannelLogoClientside.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/header/main-header-sidebar-wrapper/client/MainHeaderSidebar.client.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/components/link/LinkWithChannel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LocaleUppercase"] */ "/usr/src/app/src/app/components/text/LocaleUppercase.tsx");
